import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import postApiCall from "../../Services/postApiCall";
import { Button } from "react-bootstrap";
import PaginationNew from "../../Widgets/PaginationNew"
import deleteApiCall from "../../Services/deleteApiCall";
import Constant from "../../Components/Constant";
import { userDetails } from "../../Services/authenticationService";
import useGetRoleModule from "../../Services/useGetRoleModule";
import axios from "axios";
import loginUser from "../../Services/loginUser";
import { TagPicker } from 'rsuite';
import { SelectPicker, Tooltip, Whisper, } from 'rsuite';
const PropertyListing = () => {
  let { accessToken } = loginUser();

  const [lists, setLists] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(Constant.perPage);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchForm, setSearchForm] = useState({
    searchKey: "",
    fromDate: "",
    toDate: "",
    soldStatus: "",
  });
  const [permission, setPermission] = useState({});
  const [auctionPermission, setAuctionPermission] = useState({});
  const [partner, setPartner] = useState("")
  const [partnerArr, setPartnerArr] = useState([])
  const [hoverText,setHoverText] = useState('')

  const user = userDetails();

  function changeSearchForm(e) {
    if (!e.target.name) return;
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value,
    });
  }

  const onChangeFilter = (e) => {
    if (!e.target.name) return;
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value,
    });
  };
  const submitFilter = (e) => {
    e.preventDefault();
    getList();
    // getEmenities()
  };

  async function getList() {
    let response = await postApiCall("user/property/get-all", {
      page: pageNo,
      contentPerPage: perPage,
      searchKey: searchForm.searchKey,
      fromDate: searchForm.fromDate,
      toDate: searchForm.toDate,
      soldStatus: searchForm.soldStatus,
      propertyFor: 1,
      partnerArr,
    });
    setLists(response.data);
    setTotalItems(response.total);
    setIsLoaded(true);
  }

  async function GetRole() {
    let Role = await useGetRoleModule("properties");
    let autionRole = await useGetRoleModule("property/auction-bulk-upload");
    if (Role.moduleList.read === false) {
      setPermission({
        moduleAccress: false,
        moduleList: {},
        message: "Module Need Some Permission...Pls contact with Your Partner",
      });
    } else {
      if(Role.role == "admin"){
        let partnerLis = await postApiCall("admin/partner/getPartnerUser", {}, true);
        if(partnerLis.meta.status){
            let out = partnerLis.data.map(_ => {
                return {
                  label: _.parnterName, value: _.partnerId 
                }
            })
            setPartner(<div className="w-25">
      <div className="form-group">
      <b style={{ color:'#000000'}}>Partner </b>
       <TagPicker data={out} style={{ width: 300 }} onChange={(e) => tagPickerHandler(e)}/>
    </div>
    </div>)
        }

       
      }

      setAuctionPermission(autionRole);
      setPermission(Role);
    }

    // getList();
  }

  async function tagPickerHandler(e){
    setPartnerArr(e)
  }

  useEffect(() => {
    GetRole();
  }, []);

  function UpdateStatus(e) {
    let id = e.currentTarget.getAttribute("value");
    let status = e.currentTarget.getAttribute("status");
    let isDeleted = e.currentTarget.getAttribute("isDeleted");
    // console.log('status', status)
    // status = status === 1 ? "active" : "deactive"
    status = status == 0 ? "active" : "deactive";
    // console.log('status', status)

    postApiCall("user/property/status", {
      status: status,
      _id: id,
      // isDeleted: isDeleted
    }).then((response) => {
      if (response.meta.status) {
        swal({ text: response.meta.msg, icon: "success", timer: 1500 });
        getList();
      }
    });
  }
 
  function DeleteEvent(e) {
    let id = e.currentTarget.getAttribute("value");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApiCall("user/property/delete/" + id, {}).then((response) => {
          if (response.meta.status) {
            swal({ text: response.meta.msg, icon: "success", timer: 1500 });
            getList();
          }
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  }

  const exportToExcel = async () => {
    try {
      const requestData = {
        searchKey: searchForm.searchKey,
        fromDate: searchForm.fromDate,
        toDate: searchForm.toDate,
        soldStatus: searchForm.soldStatus,
        propertyFor: 1
      };

      const response = await axios({
        url: Constant.apiBasePath + "user/property/download",
        method: "POST", // Changed to POST
        responseType: "blob",
        headers: {
          authkey: accessToken,
          "Content-Type": "application/json", // Added content type for POST request
        },
        data: JSON.stringify(requestData), // Send data in the request body
      });

      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "properties.xlsx";
      document.body.appendChild(link);
      link.click();
      // Clean up the URL object when the download is complete
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  function convertDate(date) {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month?.length < 2) month = "0" + month;
    if (day?.length < 2) day = "0" + day;
    return [day, month, year].join("-");
  }

  function pageChangeHandler(page) {
    if (isLoaded) {
      setPageNo(page);
    }
  }

  useEffect(() => {
    getList();
  }, [pageNo]);

  function truncateText(value) {
    let truncated = value.substring(0, 50) + "...";
    return truncated;
  }

  function handleMouseOver(e){
    setHoverText(e)
  }

  return (
    <>
      <div className="container-fluid">
        <div className="main-title">
          <h3>Property Management</h3>
        </div>

        {permission.hasOwnProperty("moduleAccress") &&
        !permission.moduleAccress ? (
          <div className="row text-center">
            <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="errer">
                <img src="/program-error.png" />
                <h2>403</h2>
                {/* <h4 className="text-danger">{permission.message}</h4> */}
                <p>{permission.message}</p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-sm-flex align-items-center justify-content-end mb-4">
              {permission?.moduleList?.createDisabled ? null : (
                <Link
                  to="/property/add"
                  className="d-sm-inline-block mr-1 btn btn-sm btn-primary shadow-sm"
                >
                  Add Property
                </Link>
              )}

              
              <Button
                onClick={exportToExcel}
                className="d-sm-inline-block btn btn-sm btn-primary shadow-sm ml-1"
              >
                Download to Excel
              </Button>
            </div>

            <div className="card shadow mb-4">
              {/*Search and Filter From*/}
              <div className="card-body">
                <form onSubmit={submitFilter} onChange={onChangeFilter}>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-md-9 col-lg-9">
                      <div className="d-flex flex-row">
                        <div className="col-3">
                          <div className="form-group">
                          <b style={{ color:'#000000'}}>Search </b>
                            <input
                              type="text"
                              className="form-control"
                              name="searchKey"
                              value={searchForm.searchKey}
                              placeholder="Search By Partner Name, Property ID, City, State, Address, Project Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 ">
                          <div className="form-group">
                          <b style={{ color:'#000000'}}>From Date </b>
                            <input
                              type="date"
                              className="form-control"
                              name="fromDate"
                              value={searchForm.fromDate}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 ">
                          <div className="form-group">
                          <b style={{ color:'#000000'}}>End Date </b>
                            <input
                              type="date"
                              className="form-control"
                              name="toDate"
                              value={searchForm.toDate}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 ">
                          <div className="form-group">
                          <b style={{ color:'#000000'}} htmlFor="exampleFormControlSelect1">
                              Sold Status
                            </b>
                            <select
                              name="soldStatus"
                              value={searchForm.soldStatus}
                              className="form-control"
                            >
                              <option value="">Select</option>
                              <option value="YES">Yes</option>
                              <option value="NO">No</option>
                            </select>
                          </div>
                        </div>
                       <div>
                       {partner}
                       </div>
                          {/* load partner dropdown only for admin */}


                        {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Status</label>*/}
                        {/*        <select className="form-control" name="status" value={searchForm.status}>*/}
                        {/*            <option value="">Select</option>*/}
                        {/*            <option value="active">Active</option>*/}
                        {/*            <option value="deactivate">Deactivate</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Type</label>*/}
                        {/*        <select className="form-control" name="type" value={searchForm.type}>*/}
                        {/*            <option value="">All</option>*/}
                        {/*            <option value="ROOT">Root</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                    <div className="mt-1" style={{ marginLeft: "8rem"}}>
                      <div className="form-group mt-3">
                        <label> </label>
                        <button
                          type="submit"
                          className="btn btn-md btn-primary shadow-sm  mr-2"
                        >
                          {" "}
                          Search & Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Property Id</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Address</th>
                        <th>Financial Institution Name</th>
                        <th>Posted Date</th>
                        {/*<th>Type Of Property</th>*/}
                        {/*<th>Feedbacks</th>*/}
                        {/* <th>Property Status</th> */}
                        {/*{user.serviceType === 2 || user.role == 'admin' ? <th>Bids</th> : ''}*/}
                        {/*{user.serviceType === 2 ? <th>Bids</th> : ''}*/}
                        {permission?.moduleList?.deleteDisabled &&
                        permission?.moduleList?.updateDisabled ? null : (
                          <th>Action</th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {lists.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1 + (pageNo - 1) * 10}</td>
                            <td>
                              <Link to={"/property/details/" + item._id}>
                                {item.propertyId}
                              </Link>
                            </td>
                            <td>{item.stateName}</td>
                            <td>{item.cityName}</td>
                            <td className="new-tooltip"  onMouseOver={()=> handleMouseOver(item.address)}>{(item.address)}
                            <span>{hoverText}</span>
                            </td>
                            <td>{item.partner ? item.partner.name : ""}</td>

                            <td>{convertDate(item.createdAt)}</td>
                            {/*<td>{item.createdAt ? }</td>*/}
                            {/*<td>{item.propertyType == 1 ? 'Residential' : 'Commercial'}</td>*/}
                            {/*<td><Link className={'btn btn-warning btn-sm'}*/}
                            {/*          to={'/properties/feedbacks/' + item._id}>Feedbacks</Link>*/}
                            {/*</td>*/}
                            {/* <td>{item.status ? "Active" : "Inactive"}</td> */}

                            {/*{user.serviceType && item.propertyFor === 2 ?*/}
                            {/*    <td><Link className={'btn btn-warning btn-sm'}*/}
                            {/*              to={'/properties/bids/' + item._id}>Bids</Link>*/}
                            {/*    </td> : ''}*/}
                            {permission?.moduleList?.deleteDisabled &&
                        permission?.moduleList?.updateDisabled ? null : (
                            <td  className="d-flex flex-row">
                              {permission?.moduleList
                                ?.updateDisabled ? null : !item.status ? (
                                <button
                                  onClick={UpdateStatus}
                                  value={item._id}
                                  isDeleted={item.isDeleted}
                                  status={item.status}
                                  className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="fas fa-exclamation-triangle"></i>
                                  </span>
                                  {/*<span className="text">Disable</span>*/}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                  onClick={UpdateStatus}
                                  value={item._id}
                                  isDeleted={item.isDeleted}
                                  status={item.status}
                                >
                                  <span className="icon text-white-50">
                                    <i className="fas fa-check"></i>
                                  </span>
                                  {/*<span className="text">Enable</span>*/}
                                </button>
                              )}

                              {permission?.moduleList?.updateDisabled ? null : (
                                <Link
                                  to={"/property/add?id="+ item._id}
                                  className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="far fa-edit"></i>
                                  </span>
                                  {/*<span className="text">Edit</span>*/}
                                </Link>
                              )}

                              {permission?.moduleList?.deleteDisabled ? null : (
                                <button
                                  onClick={DeleteEvent}
                                  value={item._id}
                                  className="btn btn-danger btn-icon-split btn-sm mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="fa fa-trash"></i>
                                  </span>
                                  {/*<span className="text">Delete</span>*/}
                                </button>
                              )}
                                 <Whisper followCursor placement="left" speaker={<Tooltip>Audit Trail</Tooltip>}>
                              <a href={`/properties/audit-trails/${item._id}`}><button className="btn btn-icon-split btn-sm mb-1 mr-1" style={{backgroundColor: 'black'}}>
                                <span className="icon" style={{color: 'white'}}>
                                   <i class="fa fa-history" aria-hidden="true"></i>
                                </span>
                              </button></a>
                              </Whisper>
                            </td>)}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="justify-content-center mt-2">
                    <PaginationNew
                      perPage={perPage}
                      totalItems={totalItems}
                      currentPage={pageNo}
                      handler={pageChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PropertyListing;
