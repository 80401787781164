import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import getApiCall from "../../Services/getApiCall";
import postApiCall from "../../Services/postApiCall";
import swal from "sweetalert";

import deleteApiCall from "../../Services/deleteApiCall";
import useGetRoleModule from '../../Services/useGetRoleModule';

const BlogCategoryListing = () => {
    const [lists, setLists] = useState([]);
    const [filterForm, setFilterForm] = useState({
        status: '',
        searchKey: '',
        'type': '',
    });
    const [permission, setPermission] = useState({})

    async function getEmenities() {
        let response = await getApiCall('common/blog/category/list', filterForm);
        if(response.meta.status == true) setLists(response.data != undefined ? response.data : [])
        else setLists([])
    }


    async function GetRole() {
        let Role = await useGetRoleModule("blogs/categories");
        if(Role.moduleList.read === false){
            setPermission({moduleAccress : false, moduleList:{}, message:"Module Need Some Permission...Pls contact with Your Partner"})
        }else{
            setPermission(Role)
            getEmenities();
        }
        
    }

    useEffect(() => {
        GetRole()
    }, []);


    function UpdateStatus(e) {
        let id = e.currentTarget.getAttribute('value');
        let status = e.currentTarget.getAttribute('status');
        let isDeleted = e.currentTarget.getAttribute('isDeleted');
        status = status === "DEACTIVE" ? "ACTIVE" : "DEACTIVE"
        postApiCall('common/blog/category/status', {
            categoryId: id,
            status: status,
        }).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getEmenities();
            }
        });
    }

    function deleteEvent(e) {
        let id = e.currentTarget.getAttribute('value');
        if (!id) return;
        deleteApiCall('common/blog/category/delete/' + id, {}).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getEmenities();
            }
        });
    }

    const onChangeFilter = (e) => {
        if (!e.target.name) return
        setFilterForm({
            ...filterForm,
            [e.target.name]: e.target.value
        })
    }
    const submitFilter = (e) => {
        e.preventDefault();
        getEmenities()
    }

    return (
        <>
            <div className="container-fluid">
            {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    :
                    (Object.keys(permission).length > 0) ?
                <>
                <div className="main-title"><h3> Blog Categories</h3></div>
                <div className="d-sm-flex align-items-center justify-content-end mb-4">
                    
                    {permission.moduleList.createDisabled ? null :
                    <Link to="/blogs/categories/add" className="d-sm-inline-block btn btn-sm btn-warning shadow-sm">
                        Add New
                    </Link> }
                </div>
                <div className="card shadow">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Search and Filter</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitFilter} onChange={onChangeFilter}>
                            <div className="row">
                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Search </label>
                                        <input type="text" className="form-control" name="searchKey"
                                               value={filterForm.searchKey}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control" name="status" value={filterForm.status}>
                                            <option value="">Select</option>
                                            <option value="active">Active</option>
                                            <option value="deactivate">Deactivate</option>
                                        </select>
                                    </div>
                                </div>
                                {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label>Type</label>*/}
                                {/*        <select className="form-control" name="type" value={filterForm.type}>*/}
                                {/*            <option value="">All</option>*/}
                                {/*            <option value="ROOT">Root</option>*/}
                                {/*        </select>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                <div className="form-group mt30">
                                    <button type="submit" className="btn btn-md btn-warning shadow-sm  mr-2"> Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%" cellSpacing="0">
                                <thead>
                                <tr>
                                    <th className="width5">Sl. No.</th>
                                    <th>Name</th>
                                    {/*<th>Type</th>*/}
                                    <th>Status</th>
                                    {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null :   <th class="width25">Action</th> }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    lists.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.categoryName}</td>
                                            {/*<td>{item.type}</td>*/}
                                            <td>{item.status}</td>
                                            {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null : <td>
                                                {permission.moduleList.updateDisabled ? null : item.status == "DEACTIVE" ?
                                                    <button
                                                        onClick={UpdateStatus} value={item.categoryId}
                                                        isDeleted={item.isDeleted} status={item.status}
                                                        className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1"><span
                                                        className="icon text-white-50"><i
                                                        className="fas fa-exclamation-triangle"></i></span>
                                                        <span className="text">Disable</span>
                                                    </button>
                                                    :
                                                    <button className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                                            onClick={UpdateStatus} value={item.categoryId}
                                                            isDeleted={item.isDeleted}
                                                            status={item.status}
                                                    >
                                                    <span className="icon text-white-50"><i
                                                        className="fas fa-check"></i></span>
                                                        <span className="text">Enable</span>
                                                    </button>
                                                }
                                              {permission.moduleList.updateDisabled ? null :
                                                <Link to={"/blogs/categories/add?id=" + item.categoryId}
                                                      className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1">
                                                    <span className="icon text-white-50">
                                                        <i className="far fa-edit"></i>
                                                    </span>
                                                    <span className="text">Edit</span>
                                                </Link>}
                                                {permission.moduleList.deleteDisabled ? null :
                                                <button onClick={deleteEvent} value={item.categoryId}
                                                        className="btn btn-danger btn-icon-split btn-sm mb-1">
                                                        <span className="icon text-white-50">
                                                            <i className="far fa-eye"></i>
                                                        </span>
                                                    <span className="text">Delete</span>
                                                </button>}
                                            </td>}
                                        </tr>

                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </> : null}


            </div>
        </>
    )
}

export default BlogCategoryListing
