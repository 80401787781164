import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import Layout from "../../Layout";
import getApiCall from "../../Services/getApiCall";
import postApiCall from "../../Services/postApiCall";
import swal from "sweetalert";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import useGetRoleModule from '../../Services/useGetRoleModule';
import Constant from "../../Components/Constant";
import PaginationNew from "../../Widgets/PaginationNew";

const AddressLocationListing = () => {
    const [lists, setLists] = useState([]);
    const [permission, setPermission] = useState({})
    const [pageNo, setPageNo] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    // const [perPage, setPerPage] = useState(Constant.perPage);
    const [perPage, setPerPage] = useState(10);
    const [isLoaded, setIsLoaded] = useState(true);

    function pageChangeHandler(page) {
        // if (isLoaded) {
        setPageNo(page);
        // getList()
        // }
    }

    useEffect(() => {
        GetRole()
    }, [pageNo]);


    async function getList() {
        let response = await getApiCall('common/location/list', {
            page: pageNo,
            contentPerPage: perPage,
        });
        setLists(response.data)
        setTotalItems(response.total)

        setIsLoaded(true);
    }

    async function GetRole() {
        let Role = await useGetRoleModule("address-locations");
        if(Role.moduleList.read === false){
            setPermission({moduleAccress : false, moduleList:{}, message:"Module Need Some Permission...Pls contact with Your Partner"})
        }else{
            setPermission(Role)
            getList();
        }
        
    }

    useEffect(() => {
        GetRole()
    }, []);


    function UpdateStatus(e) {
        let id = e.currentTarget.getAttribute('value');
        let status = e.currentTarget.getAttribute('status');
        status = status == "DEACTIVE" ? "ACTIVE" : "DEACTIVE"
        postApiCall('common/location/status', {
            "locationId": id,
            status: status,
        }).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getList();
            }
        });
    }

    function Delete(e) {
        let id = e.currentTarget.getAttribute('value');
        deleteApiCall('common/location/delete/' + id, {}).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getList();
            }
        });
    }


    return (
        <>
            <div className="container-fluid">
                <div className="main-title">
                    <h3>Location Management</h3>
                </div>

                {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    : (Object.keys(permission).length > 0) ?
                    <>
                        <div className="d-sm-flex align-items-center justify-content-end mb-4">
                            {permission.moduleList.createDisabled ? null :
                                <Link to="/address-locations/add"
                                      className="d-sm-inline-block btn btn-sm btn-warning shadow-sm">
                                    Add New
                                </Link>}
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-body">

                                <div className="table-responsive">
                                    <table className="table table-bordered" width="100%" cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>Sl. No.</th>
                                            <th>Address</th>
                                            <th>State</th>
                                            <th>City</th>
                                            <th>Latitude</th>
                                            <th>Longitude</th>
                                            <th>Status</th>
                                            {/*<th>Type</th>*/}
                                            {/*<th>Icon</th>*/}
                                            {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null :
                                                <th>Action</th>}
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            lists.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{(index + 1)+((pageNo-1)*10)}</td>
                                                    <th>{item.address}</th>
                                                    <th>{item.state}</th>
                                                    <th>{item.city}</th>
                                                    <th>{item.latitude}</th>
                                                    <th>{item.longitude}</th>
                                                    <th>{item.status}</th>
                                                    {/*<td>{item.type == 1 ? 'Residential' : 'Commercial' }</td>*/}
                                                    {/*<td><img src={item.icon} width={50} height={50} alt=""/></td>*/}
                                                    {permission.moduleList.updateDisabled ? null : <td>
                                                        
                                                            {item.status == "DEACTIVE" ?
                                                                <button
                                                                    onClick={UpdateStatus} value={item.locationId}
                                                                    status={item.status}

                                                                    className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1"><span
                                                                    className="icon text-white-50"><i
                                                                    className="fas fa-exclamation-triangle"></i></span>
                                                                    <span className="text">Disable</span>
                                                                </button>
                                                                :
                                                                <button
                                                                    className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                                                    onClick={UpdateStatus} value={item.locationId}
                                                                    status={item.status}
                                                                >
                                                    <span className="icon text-white-50"><i
                                                        className="fas fa-check"></i></span>
                                                                    <span className="text">Enable</span>
                                                                </button>
                                                        }

                                                        {permission.moduleList.updateDisabled ? null :
                                                            <Link to={"address-locations/edit/" + item.locationId}
                                                                  className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1">
                                                    <span className="icon text-white-50">
                                                        <i className="far fa-edit"></i>
                                                    </span>
                                                                <span className="text">Edit</span>
                                                            </Link>}

                                                        {permission.moduleList.deleteDisabled ? null :
                                                            <button onClick={Delete} value={item.locationId}
                                                                    className="btn btn-danger btn-icon-split btn-sm mb-1">
                                                        <span className="icon text-white-50">
                                                            <i className="far fa-eye"></i>
                                                        </span>
                                                                <span className="text">Delete</span>
                                                            </button>}
                                                    </td> }
                                                </tr>

                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <div className="justify-content-center mt-2">
                                        <PaginationNew perPage={perPage} totalItems={totalItems}
                                                       currentPage={pageNo}
                                                       handler={pageChangeHandler}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ''}


            </div>
        </>
    )
}

export default AddressLocationListing
