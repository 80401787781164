import Layout from "../Layout";
import getApiCall from "../Services/getApiCall";
import {useEffect, useState} from "react";
import {decryptBody} from "../Services/helpers";
export default function Dashboard () {
    const [data, setData] = useState({
        totalLeads: 0,
        totalProperty: 0,
    });

    useEffect(() => {
        getData();
    }, []);
    async function getData() {
        getApiCall('common/upload/blob/admin/token')
           .then(response => {
               sessionStorage.setItem('uploadT', decryptBody(response.data).token)
            })
        getApiCall('admin/dashboard').then((data) => {
            setData(data.data)
        })
    }

    return <>
        <div className="container-fluid">
        <div className="main-title">
                        <h3>Dashboard</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card dashboard-card">
                                <div className="card-body">
                                    <h6 className="card-title">Total Leads</h6>
                                    <h4>{data.totalLeads}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card dashboard-card">
                                <div className="card-body">
                                    <h6 className="card-title">Total Property</h6>
                                    <h4>{data.totalProperty}</h4>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    </>
}