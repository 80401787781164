import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom';
import getApiCall from "../../Services/getApiCall";
import swal from "sweetalert";
import axios from "axios";

import patchApiCall from "../../Services/patchApiCall";
import Constant from "../../Components/Constant";
import postApiCall from '../../Services/postApiCall';
import PaginationNew from "../../Widgets/PaginationNew";
import useGetRoleModule from '../../Services/useGetRoleModule';
import { DateRangePicker, Stack} from 'rsuite';
import "rsuite/dist/rsuite.css";
import moment from 'moment'
import loginUser from "../../Services/loginUser";
import { SelectPicker } from 'rsuite';

const BidderListing = () => {
    let {accessToken} = loginUser();

    const [lists, setLists] = useState([]);
    const [filterForm, setFilterForm] = useState({ searchKey: '', auctionStatus:"", isEoi:"", bidderStatus:"", partnerName: "", startDate:"", endDate:"", isExcelDownload: false});
    const [pageNo, setPageNo] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [perPage, setPerPage] = useState(Constant.perPage);
    const [isLoaded, setIsLoaded] = useState(true);
    const [permission, setPermission] = useState({})
    const [partner, setPartner] = useState("")
    const [partnerArr, setPartnerArr] = useState([])

    function pageChangeHandler(page) {
        // if (isLoaded) {
        setPageNo(page);
        // getList()
        // }
    }

    const location = useLocation();
    const myparam = location.state?.params;


    async function GetRole() {
        let Role = await useGetRoleModule("bidders");
        setPermission(Role)

        let partnerLis = await postApiCall("admin/partner/getPartnerUser", {}, true);
        if(partnerLis.meta.status){
            let out = partnerLis.data.map(_ => {
                return {
                  label: _.parnterName, value: _.parnterName 
                }
            })
            if(Role.role == "admin"){
                setPartner(<div className="col-12 col-xs-3 col-md-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Partner
                </label>
                <SelectPicker data={out} style={{ width: 300 }} onChange={(e) => tagPickerHandler(e)}/>
            </div>
            </div>)
              }
        }



        getList();
    }

    
    async function tagPickerHandler(e){
        // console.log(e)
        setFilterForm({
            ...filterForm,
            ["partnerName"]: e
        })
      }

    useEffect(() => {
        GetRole();
    }, [])

    useEffect(() => {
        getList();
    }, [pageNo])


    const onChangeFilter = (e) => {
        if (!e.target.name) return
        setFilterForm({
            ...filterForm,
            [e.target.name]: e.target.value
        })
    }

    const dateRangeHandler = (e) => {
        if(e){
            let startDate = e[0]
            let endDate = e[1]
            startDate = moment(startDate).format('YYYY/MM/DD');
            endDate = moment(endDate).format('YYYY/MM/DD');
            setFilterForm({
                ...filterForm,
                startDate,
                endDate
            })
        }else{
            setFilterForm({
                ...filterForm,
                startDate:"",
                endDate:""
            })
          //  console.log("DATE RANGE CLEARED")
        }
    }

    const submitFilter = (e) => {
        e.preventDefault();
        search();
    }
    useEffect(() => {
        getList();
    }, [])

   

    function getList() {
        filterForm.contentPerPage = perPage
        filterForm.page = pageNo

        getApiCall('user/bid/bidder/list', filterForm).then((response) => {
            if (response.meta.status) {
                setLists(response.data)
                setTotalItems(response.total)
            } else {
                setLists([])
                setTotalItems(0)
            }
        })
            .catch((error) => {
                setLists([])
                setTotalItems(0)
            })

    }

    function search() {
        filterForm.contentPerPage = perPage
        filterForm.page = 1

        getApiCall('user/bid/bidder/list', filterForm).then((response) => {
            if (response.meta.status) {
                setLists(response.data)
                setTotalItems(response.total)
            } else {
                setLists([])
                setTotalItems(0)
            }
        })
            .catch((error) => {
                setLists([])
                setTotalItems(0)
            })

    }


    async function downloadList(e) {
        e.preventDefault();
        // Download to export
        const response = await axios({
            url: Constant.apiBasePath + 'user/bid/bidder/list',
            method: 'GET',
            responseType: 'blob',
            headers: {
                authkey: accessToken
            },
            params: {...filterForm, isExcelDownload: true}
        });
        // getApiCall('user/bid/bidder/list', )
        //     .then((response) => {
                const url = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'bidder.xlsx';
            document.body.appendChild(link);
            link.click();
            // URL.revokeObjectURL(url)
            // })


        // let data = filterForm
        // data.exportData = 1;
        // getList(data)
    }


    function UpdateStatus(e) {
        let id = e.currentTarget.getAttribute('value');
        let status = e.currentTarget.getAttribute('status');
        let isDeleted = e.currentTarget.getAttribute('isDeleted');
        status = status === "DEACTIVE" ? "ACTIVE" : "DEACTIVE"
        if (isDeleted) status = "DELETE"
        patchApiCall('common/static/page/changeStatus', {
            'pageId': id,
            status: status,
            // isDeleted: isDeleted
        }).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getList();
            }
        });
    }

    const sourceName =(data)=>{
        if(!data){
            return '-';
        }
        if(data && data.source === 'iifl-employee'){
            return 'IIFL EMPLOYEE'
        }else if( data.source === 'no-broker'){
            return 'NO BROKER'
        }else if(data.source === 'nexxen-disposal-hub'){
            return 'NEXXEN DISPOSAL HUB'
        }else if(data.source === 'hecta'){
            return 'HECTA   '
        }else if(data.source === 'broker'){
            return 'BROKER'
        }else{
            return 'SELF'
        }
    }


    return (
        <>
             {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    : <div className="container-fluid">
            <div className="main-title">
                    <h3>Bidders</h3>
                </div>
                
               
                    <div className="d-sm-flex align-items-center justify-content-end mb-4">
                   
                    {/*<Link to="/cms/add" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">*/}
                    {/*    Add New*/}
                    {/*</Link>*/}
                    <button type="button" onClick={downloadList}
                            className="btn btn-sm btn-warning shadow-sm  mr-2"> Download List
                    </button>

                </div>

                <div className="card shadow mb-4">
                    <div className="card-header">
                        <div className="card-title">Search and Filters</div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitFilter} onChange={onChangeFilter}>
                            <div className="row">
                            <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                  <div className="field">
                                     <p>Auction Date</p>
                                     <DateRangePicker onChange={(e) => dateRangeHandler(e)}/>
                                     </div>
                                </div>

                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Auction Status</label>
                                        <select className="form-control" name="auctionStatus"
                                                defaultValue={filterForm.auctionStatus} >
                                            <option value="">Select</option>
                                            <option value="LIVE">Live Auction</option>
                                            <option value="UPCOMING">Live Events</option>
                                            <option value="PAST">Completed</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-xs-3 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Search </label>
                                        <input type="text" className="form-control" name="searchKey"
                                               defaultValue={filterForm.searchKey}
                                               placeholder="Search by Bidder Name,Property ID,Auction ID,Property State, Property City"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-xs-3 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Auction Type</label>
                                        <select className="form-control" name="isEoi"
                                                defaultValue={filterForm.isEoi}>
                                            <option value="">Select</option>
                                            <option value="true">EOI</option>
                                            <option value="false">EMD</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Bidder Status</label>
                                        <select className="form-control" name="bidderStatus"
                                                defaultValue={filterForm.bidderStatus}>
                                            <option value="">Select</option>
                                            <option value="WIN">Win</option>
                                            <option value="LOST">Lost</option>
                                            <option value="NP">Not Participated</option>

                                        </select>
                                    </div>
                                </div>

                                {/* load partner dropdown only for admin */}
                                {partner}


                                <div className="col-12 col-xs-3 col-md-3 col-lg-3 mt-1">
                                    <div className="form-group mt-4">
                                        <label> </label>
                                        <button type="submit" className="btn btn-md btn-warning shadow-sm  mr-2"> Search
                                            & Filter
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col"></div>
                        </div>

                        <div className="card-header"></div>
                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%" cellSpacing="0" style={{display: 'block', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                <thead>
                                <tr>
                                    <th>Sl. No.</th>
                                    <th>Date of registration</th>
                                    <th>Participation ID</th>
                                    <th>Bidder Type</th>
                                    <th>Bidder Name</th>
                                    <th>Bidder Email ID</th>
                                    <th>Bidder Mobile Number</th>
                                    <th>Financial Institution Name</th>
                                    <th>Auction Type</th>
                                    <th>Property ID</th>
                                    <th>Auction Id</th>
                                    <th>Auction Date</th>
                                    <th>Auction Status</th>
                                    <th>EMD payment amount</th>
                                    <th>E-sign</th>
                                    <th>Property State</th>
                                    <th>Property City</th>
                                    <th>Property Reserve Price</th>
                                    <th>Bidder Status</th>
                                    <th>Referral Source</th>
                                    <th>Referral Code</th>
                                    <th>Referee Details</th>
                                    <th>Sale Status</th>
                                    {/* <th>Sold/HBL Date</th> */}
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    lists.length > 0 ? lists.map((item, index) => {
                                        let curr = moment().valueOf();
                                        let status = '';
                                        if (item?.isVerified) {
                                            status = "Accepted"
                                        } else if (!item?.isVerified && !item?.bidderRejectReason) {
                                            status = 'Pending';
                                        } else if (!item?.isVerified && item?.bidderRejectReason) {
                                            status = 'Rejected';
                                        }
                                        return <tr key={index}>
                                            <td>{(index + 1) + ((pageNo - 1) * 10)}</td>
                                            <td>{item?.bidder?.createdAt ? item?.bidder?.createdAt.split("T")[0]: 'NA'}</td>
                                            <td><a href={`/bidder/bidder_overviews/${item.order.participationId}`}>{item.order.participationId !== undefined ? item.order.participationId : "-"}</a></td>
                                            <td>{item.bidder.type}</td>
                                            <td>{item.bidder.type != "COMPANY" ? item.bidder.name: item.bidder.companyName}</td>
                                            <td>{item.bidder.email}</td>
                                            <td>{item.bidder.mobile}</td>
                                            <td>{item.partner.name}</td>
                                            <td>{item.order.isEoi === true ? "EOI" : "EMD"}</td>
                                            <td>{item.propertyDetails.propertyId}</td>
                                            <td>{item.propertyDetails.auctionId}</td>
                                            <td>{item.propertyDetails.auctionDate}</td>
                                            <td>{item.propertyDetails.auctionStatus}</td>
                                          
                                            <td>{item.order.amount}</td>
                                            <td>{item.isSigned ? 'Done':'Pending'}</td>
                                            <td>{item.propertyDetails.stateName}</td>
                                            <td>{item.propertyDetails.cityName}</td>
                                            <td>{item.propertyDetails.price}</td>
                                            <td>{item.propertyDetails.auctionEndDateTimeEpoch > curr ? "-" : item.isBidAwarded === true ? "Win" : item.isParticipatedInAuction === true ? "Lost" : "Not Participated"}</td>
                                            <td>{sourceName(item?.referrerInfo)}</td>
                                            <td>{item?.referrerInfo?.source === 'self' ? '-' :  item?.referrerInfo?.source === 'broker' ? item?.referrerInfo?.referrerDetails?.name :  item?.referrerInfo?.referrerDetails?.empCode}</td>
                                            <td>{item?.referrerInfo?.source === 'self' || item?.referrerInfo?.source === 'iifl-employee' ? '-' : item?.referrerInfo?.source === 'broker' ? item?.referrerInfo?.referrerDetails?.mailId +' , ' +item?.referrerInfo?.referrerDetails?.panCard : item?.referrerInfo?.referrerDetails?.name}</td>
                                            <td>{item?.propertyDetails?.soldStatus == "YES" ? 'Sold' : item?.propertyDetails?.soldStatus == "NO" && item?.propertyDetails?.isAwarded ? 'HBL' : 'UNSOLD'  }</td>
                                            
                                        </tr>
                                    }) : <tr><td colspan={15} style={{textAlign: 'center'}}>No Records</td></tr>
                                }
                                </tbody>
                            </table>

                            <div className="justify-content-center mt-2">
                                <PaginationNew perPage={perPage} totalItems={totalItems}
                                               currentPage={pageNo}
                                               handler={pageChangeHandler}/>
                            </div>
                        </div>
                    </div>
                </div>


            </div> }
        </>
    )
}

export default BidderListing
