import React, { useState } from 'react';
import getApiCall from "../../Services/getApiCall";
import postApiCall from '../../Services/postApiCall';
import moment from 'moment';

import swal from 'sweetalert';
import { FilePond } from "react-filepond";
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Constant from "../../Components/Constant";
import loginUser from "../../Services/loginUser";
import { toast } from 'react-toastify';
import { ToWords } from 'to-words';

let { accessToken } = loginUser();


const BidderAddForm = () => {
  const [formData, setFormData] = useState({
    prospectNo: '',
    auctionId: '',
    auctionDate: '',
    typeOfPayment: '',
    reservePrice: '',
    reservePayment: '',
    paymentMode: '',
    bidderMobile: '',
    bidderId: '',
    bidderEmail: '',
    bidderName: '',
    bidderPan: '',
    amount: '',
    oamount:'',
    emdAmount: '',
    transactionId: '',
    paymentDate: '',
    paymentDateTime: '',
    paymentDocUrl: '',
    tenderFormUrl: '',
    offeramount:'',
  });
  const [results, setResults] = useState([]);
  const [bidderDetial, setBidderDetails] = useState([])
  const [hasbidder, setHasBidder] = useState(false)
  const [bidderError, setBidderError] = useState('')
  const [loading, setLoading] = useState(false);
  const [errorstate, setError] = useState('')

  const [documentName, setDocumentName] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');
  const [categoryImage, setCategoryImage] = useState('');

  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
      },
    },
  });

  const handleChange = async (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (value.length <= 0) {
      setLoading(true);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      return
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setLoading(true);

    try {
      const response = await getApiCall(`user/property/prospectNo/${value}/details`);
      if (response.data.propertys.length) {
        setResults(response.data.propertys);
        setLoading(false);
        setBidderError('')
      } else {
        setLoading(true);
        setBidderError('Enter Correct Prospect Number ')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }

  };
  const handleSelectProspect = (id) => {
    const filteredNumbers = results.filter(res => res._id === id);
    setLoading(true);
    let bidStatus = '';
    if (moment(filteredNumbers[0].auctionStartDateTimeEpoch).isBefore(moment()) && moment(filteredNumbers[0].auctionExtendedDateTimeEpoch).isAfter(moment())) {
      bidStatus = 'EMD';
    } else if (moment(filteredNumbers[0].auctionExtendedDateTimeEpoch).isAfter(moment())) {
      bidStatus = 'Upcoming';
    } else if (moment(filteredNumbers[0].auctionExtendedDateTimeEpoch).isBefore(moment())) {
      bidStatus = 'EOI';
    }
    setFormData((prevData) => ({
      ...prevData,
      prospectNo: filteredNumbers[0].propertyId,
      auctionId: filteredNumbers[0].auctionId,
      auctionDate: filteredNumbers[0].auctionDate,
      typeOfPayment: bidStatus,
      reservePrice: numberWithCommas("Rs " + filteredNumbers[0].price),
      reservePayment: filteredNumbers[0].price,
    }));


  }
  const handleBidderChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      bidderMobile: value,
    }));
    if (value.length === 10) {
      try {
        const response = await getApiCall(`user/bid/bidder/details?mobile=${value}`);
        if (response.data.bidderDetails) {
          setBidderDetails(response.data.bidderDetails)
          setHasBidder(true)
          // setFormData((prevData) => ({
          //   ...prevData,
          //   bidderId: response.data.bidderDetails._id,
          //   bidderEmail: response.data.bidderDetails.email,
          //   bidderName: response.data.bidderDetails.name,
          //   bidderPan: response.data.bidderDetails.PANNumber,
          // }));
          setError('')
        } else {
          setBidderDetails([])
          setHasBidder(false)
          setError('Bidder does not exist')
          setFormData((prevData) => ({
            ...prevData,
            bidderId: '',
            bidderEmail: '',
            bidderName: '',
            bidderPan: '',
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (value.length > 10) {
      setHasBidder(false)
      return

    } else {
      setError('Enter Valid Mobile Number')
      setHasBidder(false)
    }
  }
  const handleBidderselect = async (event)=>{
       setFormData((prevData) => ({
            ...prevData,
            bidderId: event._id,
            bidderEmail:event.email,
            bidderName: event.name,
            bidderPan:event.PANNumber,
          }));
      setHasBidder(false)
  }
  const handleSelectChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleTimeChange = (event) => {
    const { name, value } = event.target
    const formattedDateTime = moment(value).format('YYYY-MM-DD hh:mm:ss A');
    if(formattedDateTime !=  'Invalid date'){
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        paymentDateTime: formattedDateTime
      }));
    }

  }


  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  };

  const checkValidation = () => {

    if (!results.length) {
      return false
    }
    if (!formData.bidderId) {
      return false
    }
    if (formData.typeOfPayment === '' || formData.emdAmount === '' || formData.transactionId === '' || formData.paymentDateTime === '' || formData.paymentMode === '') {
      return false
    }
    if (!formData.paymentDocUrl) {

      return false;
    }
    return true
  }
  const submitForm = async () => {
    const validation = checkValidation()
    let payload = {}
    if (!validation) {
      return
    }
    if (validation && formData.tenderFormUrl == '') {
      payload = {
        auctionId: formData.auctionId,
        bidderId: formData.bidderId,
        transactionId: formData.transactionId,
        amount: parseInt(formData.amount),
        offerAmount: parseInt(formData.oamount),
        paymentDate: formData.paymentDateTime,
        paymentMode: formData.paymentMode,
        paymentDocUrl: formData.paymentDocUrl,
      }
    } else if (validation && formData.tenderFormUrl != '') {
      payload = {
        auctionId: formData.auctionId,
        bidderId: formData.bidderId,
        transactionId: formData.transactionId,
        amount: parseInt(formData.amount),
        offerAmount: parseInt(formData.oamount),
        paymentDate: formData.paymentDateTime,
        paymentMode: formData.paymentMode,
        paymentDocUrl: formData.paymentDocUrl,
        tenderFormUrl: formData.tenderFormUrl
      }
    }
    const response = await postApiCall('user/transaction/manual-addition', payload, true)

    if (response && response.meta.status) {
      toast.success(response.meta.msg)
    } else {
      toast.error(response.meta.msg)
    }

  }

  // Event handler for input change
  const handleInputChange = (event) => {
    // Remove non-digit characters (except commas) from the input
    const newValue = event.target.value.replace(/[^\d,]/g, '');

    // Format the number with commas
    const formattedValue = numberWithCommas(newValue.replace(/,/g, ''));


    setFormData((prevData) => ({
      ...prevData,
      emdAmount: formattedValue,
      amount: event.target.value.replace(/,/g, ''),
    }));
  };
  const handleofferChange = (event) => {
    // Remove non-digit characters (except commas) from the input
    const newValue = event.target.value.replace(/[^\d,]/g, '');

    // Format the number with commas
    const formattedValue = numberWithCommas(newValue.replace(/,/g, ''));


    setFormData((prevData) => ({
      ...prevData,
      offeramount: formattedValue,
      oamount: event.target.value.replace(/,/g, ''),
    }));
  };

  return (<>
    <div className="card-body">
      <form className="hide-number-input-arrow">
        <div className="row">
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Prospect Number *</label>
              <input type="text" name="prospectNo" className="form-control"
                value={formData.prospectNo} required={true} onChange={handleChange} />
              {loading ? (
                <></>
              ) : (
                <ul className="results-list">
                  {results.map((result) => (
                    <li key={result._id} onClick={() => handleSelectProspect(result._id)}>{result.propertyId}</li>
                  ))}
                </ul>
              )}
              <p>{bidderError}</p>
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Auction Id</label>
              <input type="text" name="projectName" className="form-control"
                value={formData.auctionId} disabled required={true} />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Auction Date</label>
              <input type="text" name="contactName" className="form-control" required={true}
                value={formData.auctionDate} disabled />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Type Of Payment</label>
              <input type="text" name="typeOfPayment" className="form-control" required={true}
                disabled value={formData.typeOfPayment} />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Reserve Price</label>
              <input type="text" name="readyToMovedate" className="form-control"
                disabled value={formData.reservePrice} required={true}
              />
              {
                parseInt(formData.reservePayment) > 0 ?
                  <span>{toWords.convert(parseInt(formData.reservePayment) || 0)}</span>
                  : <></>
              }
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Offer Amount* (in Rs)</label>
              <input type="text" name="offerAmount" className="form-control" required={true}
                value={formData.offeramount} onChange={handleofferChange}
              />
              {
                parseInt(formData.oamount) > 0 ?
                  <span>{toWords.convert(parseInt(formData.oamount) || 0)}</span>
                  : <></>
              }

            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Payment Mode *</label>
              <select name="paymentMode" className="form-control" value={formData.paymentMode} onChange={handleSelectChange} required={true}>
                <option value="">Select</option>
                <option value="IMPS">IMPS</option>
                <option value="OneHome">OneHome</option>
                <option value="RTGS">RTGS</option>
                <option value="UPI">UPI</option>
                <option value="CHEQUE">CHEQUE</option>

              </select>
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Bidder Mobile Number *</label>
              <input type="number" name="bidderMobile" className="form-control"
                maxlength="10" required={true} value={formData.bidderMobile} onChange={handleBidderChange} />
              <p>{errorstate}</p>
              {
                hasbidder ?
                
                bidderDetial.map((res) => {
                  return <>
                  {/* <span className='bidderlist' onClick={(res)=>handleBidderselect(res)}>{res.name}</span> */}
                  <option className='bidderlist' onClick={()=>handleBidderselect(res)}>{res.name}</option>
                  </>
                })
                :
                <></>

              }

            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Bidder email id </label>
              <input type="email" name="longitude" className="form-control"
                disabled value={formData.bidderEmail} required={true} />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Bidder Name</label>
              <input type="text" name="address" className="form-control" required={true}
                disabled value={formData.bidderName} />
            </div>
          </div>

          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Bidder Pan card Number </label>
              <input type="text" name="locality" className="form-control" required={true}
                disabled value={formData.bidderPan} />
            </div>
          </div>

          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>EMD Amount* (in Rs)</label>
              <input type="text" name="emdAmount" className="form-control" required={true}
                value={formData.emdAmount} onChange={handleInputChange}
              />
              {
                parseInt(formData.amount) > 0 ?
                  <span>{toWords.convert(parseInt(formData.amount) || 0)}</span>
                  : <></>
              }

            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Transaction ID*</label>
              <input type="text" name="transactionId" className="form-control" required={true}
                onChange={handleSelectChange} value={formData.transactionId} />
            </div>
          </div>

          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="exampleFormControlSelect1">Payment Date *</label>
              <input type="datetime-local" className="form-control" name="paymentDate" value={formData.paymentDate}
                required={true} onChange={handleTimeChange} />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Payment Proof*</label>
              <p></p>
              <FilePond
                maxFileSize="10MB"
                allowFileTypeValidation={true}
                acceptedFileTypes={['image/*']}
                imagePreviewMaxHeight={100}
                credits={false}
                name="image"
                labelIdle='Drag & Drop your files(Supported file formats: JPG, JPEG, PNG)'
                server={{
                  url: Constant.apiBasePath + 'common/upload/blob/admin/image',
                  process: {
                    headers: {
                      authkey: accessToken
                    },
                    onload: (res) => {
                      let data = JSON.parse(res);
                      if (data.meta.status) {
                        setCategoryImage(data.data)
                        setFormData((prevData) => ({
                          ...prevData,
                          paymentDocUrl: data.data,
                        }));
                      } else {
                        swal("use jpg, png and pdf file only");

                      }
                    }

                  }
                }}
              />
              <FilePond
                maxFileSize="10MB"
                allowFileTypeValidation={true}
                acceptedFileTypes={['application/pdf']}
                imagePreviewMaxHeight={100}
                credits={false}
                name="document"
                labelIdle='Drag & Drop your files(Supported file formats: PDF)'
                server={{
                  url: Constant.apiBasePath + 'common/upload/blob/admin/document',
                  process: {
                    headers: {
                      authkey: accessToken
                    },
                    onload: (res) => {
                      let data = JSON.parse(res);
                      if (data.meta.status) {
                        setCategoryImage(data.data)
                        setFormData((prevData) => ({
                          ...prevData,
                          paymentDocUrl: data.data,
                        }));
                      } else {
                        swal("use pdf file only");

                      }
                    }

                  }
                }}
              />
            </div>
          </div>
          <div className="col-12 col-xs-4 col-md-4 col-lg-4">
            <div className="form-group">
              <label>Tender Form </label>
              <p></p>
              <FilePond
                maxFileSize="10MB"
                allowFileTypeValidation={true}
                acceptedFileTypes={['application/pdf']}
                imagePreviewMaxHeight={100}
                credits={false}
                name="document"
                labelIdle='Drag & Drop your files(Supported Files Pdf) or <span class="filepond--label-action">Browse</span>'
                onprocessfile={(error, file) => {
                  if (!documentName) setDocumentName(file.filename)
                }}
                server={{
                  url: Constant.apiBasePath + 'common/upload/blob/admin/document',
                  process: {
                    headers: {
                      authkey: accessToken
                    },
                    onload: (res) => {
                      res = JSON.parse(res);
                      if (res.meta.status) {
                        setDocumentUrl(res.data);
                        setFormData((prevData) => ({
                          ...prevData,
                          tenderFormUrl: res.data,
                        }));
                      }
                    },

                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group mt-1">

          <button type="submit"
            className="btn btn-md btn-primary shadow-sm  mr-2" disabled={!checkValidation()} onClick={() => submitForm()}> Submit</button>

        </div>
      </form>
    </div>

  </>);
}

export default BidderAddForm;
